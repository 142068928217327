// const LoggedIn = () => import("../components/home/auth/LoggedIn")
const News = () => import("../components/home/News")
const SignUpAndIn = () => import("../components/home/auth/SignUpAndIn")
const LpPage = () => import("../components/home/LpPage")

const path = [
    {
        path: "/",
        name: "/",
        component: SignUpAndIn,
        meta: {title: "トップページ"}
    },
    {
        path: "/news",
        name: "news",
        component: News,
        meta: {title: "expo|お知らせ"}
    },
    {
        path: "/test_lp",
        name: "test_lp",
        component: LpPage,
        meta: {title: "テストページ"}
    },
]

export default path