const SettingAccount = () => import("../components/setting/information/Account")
const SettingEdit = () => import("../components/setting/request/Edit")
const SettingList = () => import("../components/setting/request/List")
const SettingAutoResponse = () => import("../components/setting/customer/autoresponse/Main")
const SettingFlag = () => import("../components/setting/customer/flag/Main")
const SettingBuyerList = () => import("../components/setting/customer/buyerlist/Main")
const SettingSoldList = () => import("../components/setting/customer/soldlist/Main")
const SettingDrop = () => import("../components/setting/drop/Main")
const SettingChatTemplate= () => import("../components/setting/customer/chattemplate/Main")

export default [
    {
        path: "/setting-account",
        name: "setting-account",
        component: SettingAccount,
        meta: {title: "expo|アカウント設定"}
    },
    {
        path: "/setting-edit",
        name: "setting-edit",
        component: SettingEdit,
        meta: {title: "expo|出品関連設定>編集"}
    },
    {
        path: "/setting-list",
        name: "setting-list",
        component: SettingList,
        meta: {title: "expo|出品関連設定>出品"}
    },
    {
        path: "/setting-auto-response",
        name: "setting-auto-response",
        component: SettingAutoResponse,
        meta: {title: "expo|顧客対応設定>自動応答"}
    },
    {
        path: "/setting-flag",
        name: "setting-flag",
        component: SettingFlag,
        meta: {title: "expo|顧客対応設定>チャットフラグ"}
    },
    {
        path: "/setting-buyerlist",
        name: "setting-buyerlist",
        component: SettingBuyerList,
        meta: {title: "expo|顧客対応設定>バイヤー名簿"}
    },
    {
        path: "/setting-sold-list",
        name: "setting-sold-list",
        component: SettingSoldList,
        meta: {title: "expo|顧客対応設定>販売データ"}
    },
    {
        path: "/setting-chat-template",
        name: "setting-chat-template",
        component: SettingChatTemplate,
        meta: {title: "expo|顧客対応設定>チャットテンプレート"}
    },
    {
        path: "/setting-drop",
        name: "setting-drop",
        component: SettingDrop,
        meta: {title: "expo|在庫管理"}
    },
]

