import Vue from 'vue'
import App from './App.vue'
import router from "./router/index"
import vuetify from "@/plugins/vuetify";
import store from "@/store";
import Mixin from "./mixins/mixin";
import VuePapaParse from "vue-papa-parse";
import VueGtm from 'vue-gtm';

Vue.use(VueGtm, {id: 'GTM-MLQK6RQ'});
Vue.use(VuePapaParse)
Vue.config.productionTip = false
Vue.mixin(Mixin)

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
