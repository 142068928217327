export default {
    namespaced: true,

    state: {
        version: "1.3.0",  // version管理
        item: {},
        user: {
            uid: "",
            email: "",
            sellers: [],
            selectedSeller: "",
            limits: {
                parallelRequest: 0,
                singleRequest: 0,
                requestPage: 0,
                requestView: 0,
                drop: 0,
            },
            count: {
                parallelRequest: 0,
                singleRequest: 0
            },
            permitSites: [],
            plans: []
        },
        snackbar: {
            flag: false,
            color: "",
            msg: "",
            timeout: 2000
        },
        overlay: {
            flag: false,
            msg: ""
        },
        policy: {
            fulfillment: "",
            payment: "",
            return: ""
        },
        planInfo: {},
        counter: {},
    },

    // 取り出し
    getters: {
        storeItem: (state) => state.item,
        storeUser: (state) => state.user,
        storeSnackbar: (state) => state.snackbar,
        storePolicy: (state) => state.policy,
        storeExpo: (state) => state.planInfo["expo"],
        storeRabbit: (state) => state.planInfo["rabbit"],
        storePlanInfo: (state) => state.planInfo,
        storeCounter: (state) => state.counter,
        storeOverlay: (state) => state.overlay,
        version: (state) => state.version,
    },
    actions: {
        changeEditItem({commit}, item) {
            commit("changeEditItem", item);
        },
        resetItem({commit}) {
            commit("resetItem");
        },
        setUser({commit}, user) {
            commit("setUser", user);
        },
        changeSnackbar({commit}, snackbar) {
            commit("changeSnackbar", snackbar);
        },
        changePolicy({commit}, policy) {
            commit("changePolicy", policy);
        },
        resetPolicy({commit}) {
            commit("resetPolicy");
        },
        planInfo({commit}, plan) {
            commit("planInfo", plan);
        },
        addCounter({commit}, counter) {
            commit("addCounter", counter);
        },
        /**
         * overlay有効化&&無効化
         * 有効化のときはmsgを入れ、無効化したい場合は引数無しで1回呼び出す
         * @param commit
         * @param msg
         */
        changeOverlay({commit}, msg="") {
            commit("changeOverlay", msg);
        },
    },

    mutations: {
        changeEditItem(state, item) {
            state.item = item;
        },
        resetItem(state) {
            // Edit.vueで空にするときにネストしてる要素はエラーとなるため初期値追加
            state.item = {
                price: [{num: 0}],
                offers: {
                    baseFlag: false,
                    lower: {flag: false},
                    upper: {flag: false}
                },
                pictures: [],
                item_specifics: {}
            };
        },
        setUser(state, user) {
            state.user = user
        },
        changeSnackbar(state, snackbar) {
            state.snackbar = snackbar
        },
        changePolicy(state, policy) {
            state.policy = policy
        },
        resetPolicy(state) {
            state.policy =
                {
                    fulfillment: "",
                    payment: "",
                    return: ""
                }
        },
        planInfo(state, plan) {
            state.planInfo = plan
        },
        addCounter(state, counter) {
            state.counter = counter
        },
        changeOverlay(state, msg) {
            state.overlay.flag = !state.overlay.flag
            state.overlay.msg = msg
        },
    },
}
;

