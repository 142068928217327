import moment from "moment";
import {v4 as uuidv4} from "uuid";
import axios from "axios";
import OriginalValidate from "@/mixins/originalValidate";
import SiteInfo from "@/mixins/siteInfo";

// import firebase, {initializeApp} from "firebase/app";
import {initializeApp} from "firebase/app";
import { httpsCallable, getFunctions } from "firebase/functions"
import {
    addDoc,
    collection,
    deleteDoc,
    deleteField,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    increment,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where,
    writeBatch,
    arrayUnion,
} from "firebase/firestore";
import {getAuth, updateEmail, sendPasswordResetEmail} from "firebase/auth";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"


const app = initializeApp({
    apiKey: process.env.VUE_APP_apiKey,
    authDomain: process.env.VUE_APP_authDomain,
    projectId: process.env.VUE_APP_projectId,
    storageBucket: process.env.VUE_APP_storageBucket,
    messagingSenderId: process.env.VUE_APP_messagingSenderId,
    appId: process.env.VUE_APP_appId,
    measurementId: process.env.VUE_APP_measurementId
});
const auth = getAuth(app)
auth.languageCode = "ja"
const storage = getStorage(app)
const functions = getFunctions(app, "us-central1")

export default {
    data: () => ({
        moment: moment,
        auth: auth,
        db: getFirestore(),
        doc: doc,
        addDoc: addDoc,
        setDoc: setDoc,
        getDoc: getDoc,
        getDocs: getDocs,
        deleteDoc: deleteDoc,
        writeBatch: writeBatch,
        collection_: collection,
        onSnapshot: onSnapshot,
        query: query,
        where: where,
        orderBy: orderBy,
        limit: limit,
        increment: increment,
        arrayUnion: arrayUnion,
        deleteField: deleteField,
        updateDoc: updateDoc,
        updateEmail: updateEmail,
        sendPasswordResetEmail: sendPasswordResetEmail,
        getDownloadURL: getDownloadURL,
        ref: ref,
        uploadBytes: uploadBytes,
        v: OriginalValidate,  // バリデーションをまとめる
        st: storage,
        siteInfo: SiteInfo,
        functions: functions,
        httpsCallable: httpsCallable,
        api: "https://api-exponential.awiiin.com/front-validation",
    }),
    methods: {
        yyyy_mm_dd_hh_mm_ss(base = "YYYY-MM-DD HH:mm:ss") {
            return moment().format(base);
        },
        getMonthAgo(month = 1, unit = "month") {
            return moment().subtract(month, unit).format("YYYY-MM-DD HH:mm:ss")
        },
        // uuid設定
        uuid: () => uuidv4(),
        // authの問題があるため、このバリデーションのみoriginalValidate外に配置
        isLogin() {
            return !!auth.currentUser
        },
        async getToken() {
            return await auth.currentUser.getIdToken(true)
        },
        // request
        makeConfig(url, method, params, headers) {
            return method === "GET" ?
                {method, url, headers, params} :
                {method, url, headers, data: params}
        },
        async request(url, method, params, headers = null) {
            let conf = this.makeConfig(url, method, params, headers)
            const res = await axios(conf)
            return res
        },
        /**apiアクセスのための共通関数
         *
         * @param url
         * @param method
         * @param params
         * @returns {Promise<AxiosResponse<any>>}
         */
        async requestWithToken(url, method, params) {
            let token = ""
            await this.getToken().then(res => token = res)
            let headers = {Authorization: `Bearer ${token}`, "Content-Type": "application/json"};
            let conf = this.makeConfig(url, method, params, headers)
            const res = await axios(conf)
            return res
        },
        // URL厳重チェック
        async checkRequest(url = "", checkType = "out") {
            const api = `${this.api}/request_url`
            try {
                const checked = await this.requestWithToken(api, "POST", {url})
                // 外側以外 or 取得件数が0件 は依頼不可なためTrueを返す
                if (checkType === "out") {
                    return checked["data"]["types"] === checkType && checked["data"]["result"] >= 1
                } else {
                    return checked["data"]["detail"] === "invalid outline url"
                }
            } catch (e) {
                console.log("error:", e)
                return false
            }
        },

        /** fsからデータ取得
         * @param ref リスト 例: ["config", "uid", "sian"]
         * @param isData: {bool}: 辞書型かリファレンスを制御
         * @returns {Promise<*|*>}
         */
        async getDocument(ref, isData = true) {
            const docRef = this.doc(this.db, ...ref);
            const res = await this.getDoc(docRef)
            return isData ? res.data() : res;
        },
        /** fsのdocumentにデータを保存
         * @param ref リスト 例: ["config", "uid", "sian"]
         * @param data
         * @param isMerge bool
         * @param isAddDoc bool
         */
        async setDocument(ref, data, isMerge = true, isAddDoc = true) {
            const docRef = this.doc(this.db, ...ref);

            // indocのboolにより保存documentにdocument_idフィールドを付け足す
            if (isAddDoc) {
                data["document_id"] = docRef.id;
            }
            await this.setDoc(docRef, data, {merge: isMerge})
        },
        /** fsのdocumentにデータを保存
         * @param ref リスト 例: ["config", "uid", "sian"]
         * @param data
         */
        async updateDocument(ref, data) {
            const docRef = this.doc(this.db, ...ref);
            await this.updateDoc(docRef, data)
        },
        /** fsフィールドの特定の値削除
         * @param ref リスト 例: ["config", "uid", "sian"]
         * @param key: リスト 削除したいフィールドのkeyを渡す 例:["userHtml", "hoge"]
         */
        async deleteDocField(ref, key) {
            const docRef = this.doc(this.db, ...ref);
            if (key.length > 1) key = key.join(".")
            await this.updateDoc(docRef, {[key]: deleteField()})
        },
        /** fsドキュメントの削除
         * @param ref リスト 例: ["config", "uid", "sian"]
         */
        async deleteDocument(ref) {
            const docRef = this.doc(this.db, ...ref);
            await this.deleteDoc(docRef)
        },
        /** fsクエリからドキュメント取得
         * @param query 下記getQueryのreturn
         * @returns {Promise<*[]>}
         */
        async getDocsFromQuery(query) {
            const querySnapshot = await this.getDocs(query)
            const docList = []
            querySnapshot.forEach(doc => {
                docList.push(doc.data())
            })
            return docList
        },

        /** fs用のクエリ生成
         * @param ref リスト 例: ["config", "uid", "sian"]
         * @param queries: records形式で渡す 例: [{field: "", term: "", value: ""}, {order: "", type: "desc"}, {limit: 100}]
         * where => {field: "", term: "", value: ""}
         * orderBy => {order: "", type: "desc"}
         * limit => {limit: 100}
         * 複数ある場合は、オブジェクトを追加
         * @returns {Query<DocumentData> | Query<unknown>}
         */
        getQuery(ref, queries) {
            // dataプロパティよりも先に呼び出されるため、大元のコレクション使用
            const col = this.collection_(this.db, ...ref)
            let q = queries.map(val => {
                if (val.field) return this.where(val.field, val.term, val.value)
                if (val.order) return this.orderBy(val.order, val.type)
                if (val.limit) return this.limit(val.limit)
            })
            return this.query(col, ...q)
        },

        // /** fs用の降順昇順クエリ生成
        //  * @param collection
        //  * @param queries: records形式で渡す 例: [{field: "", term: "", value: ""}, {order: "", type: "desc")}]
        //  * 複数ある場合は、オブジェクトを追加
        //  * @returns {Query<DocumentData> | Query<unknown>}
        //  */
        // getQueryOrderBy(collection, queries) {
        //     let [fir, sec, thi] = [0, 1, 2]
        //     const col = this._collection(this.db, collection)
        //     switch (queries.length) {
        //         case 2:
        //             return this.query(
        //                 col,
        //                 this.where(queries[fir]["field"], queries[fir]["term"], queries[fir]["value"]),
        //                 this.orderBy(queries[sec]["order"], queries[sec]["type"])
        //             )
        //         case 3:
        //             return this.query(
        //                 col,
        //                 this.where(queries[fir]["field"], queries[fir]["term"], queries[fir]["value"]),
        //                 this.where(queries[sec]["field"], queries[sec]["term"], queries[sec]["value"]),
        //                 this.orderBy(queries[thi]["order"], queries[thi]["type"])
        //             )
        //     }
        // },

        /** fs batch保存
         *
         * @param collection
         * @param records 配列で渡す 例: [{doc: str "", data: dict {}}], 注意: 500件以内
         */
        async batchData(collection, records) {
            const batch = writeBatch(this.db)

            records.forEach(obj => {
                let ref = doc(this.db, collection, obj.doc)
                batch.set(ref, obj.data, {merge: true})
            })

            await batch.commit();
        },

        async saveRequestLog(requestType, savePoint, uid) {
            /** ユーザーがリクエストした回数を毎月記録する
             * requestType: str = 依頼の種類
             * savePoint: str = 記録する場所
             * uid: str
             */
            const days = moment(new Date()).format("YYYY-MM");
            const data = {
                // 個別出品の場合: singleListing
                [requestType]: {
                    // それぞれの保存する場所の名前: beforePost
                    [savePoint]: {
                        [days]: this.increment(1),
                    }
                }
            };
            const ref = ["logs", uid]
            await this.setDocument(ref, data)
        },
        /** ユーザーがリクエストした回数を毎月記録する
         * memo: 関数名を「search」にすると予約後でエラー
         * memo: 実利用例: exclude/ExcList.vue > watch > belong > handler
         * @param targets 対象リスト: records形式のリスト型で与える 例: [{name: motoy, item_id: 123}, {name: ue, item_id: 456}]
         * @param word 対象ワード: moto
         * @param keyNames all(全キーが対象) or key name（特定キーが対象）
         * @param partType 部分一致or完全一致: part or perfect
         * @returns {string[]|*} list: ["Specify key for exact match"], [], [{name: motoy}]
         */
        mixinSearch(targets, word, keyNames = ["all"], partType = "part") {

            // 前提として完全一致の場合、検索対象は一つしかない
            // 検索対象が複数ある場合、完全一致できない {名前: 本吉, 属性: おじさん} === 名前 本吉 = 駄目です
            if (keyNames[0] === "all" && partType === "perfect") return ["Specify key for exact match"]

            const keys = keyNames[0] === "all" ? Object.keys(targets[0]) : keyNames
            return targets.filter((target) => {
                const words = keys.map((k) => target[k])
                // [abc, def]
                return partType === "part" ? new RegExp(word, "gi").test(words.join(' ')) : words[0] === word
            })
        },
        /** ローカルのクリップボードに保存(コピー)
         *
         * @param value
         * @returns {Promise<void>}
         */
        async doCopy(value) {
            await navigator.clipboard.writeText(value);
        },

        /** pythonのtime.sleepと同じ
         * @param ms:int ミリ秒で入力 例 2秒 = 2000
         * @returns {Promise<unknown>}
         */
        sleep(ms = 2000) {
            return new Promise((resolve) => {
                setTimeout(resolve, ms);
            });
        },

        mixinDownloadCsv(records, name) {
            let csv = Object.keys(records[0]) + '\n'
            const noProcess = ["Description"]
            // todo: 20220703 バグったら戻す
            //     records.forEach(val => {
            //         let itemData = Object.values(val).map(v => JSON.stringify(v))
            //         csv += itemData + "\n"
            //     })
            records.forEach(obj => {
                let itemData = []
                for (let key in obj) {
                    itemData.push(
                        noProcess.includes(key) ?
                            obj[key] : JSON.stringify(obj[key])
                    )
                }
                csv += itemData + "\n"
            })
            let blob = new Blob([csv], {type: "text/csv"})
            let link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)
            link.download = `${name}.csv`
            link.click()
            return "success"
        },
        makeCsvData(records) {
            let csv = Object.keys(records[0]) + '\n'
            const noProcess = ["Description"]
            records.forEach(obj => {
                let itemData = []
                for (let key in obj) {
                    itemData.push(
                        noProcess.includes(key) ?
                            obj[key] : JSON.stringify(obj[key])
                    )
                }
                csv += itemData + "\n"
            })
            return new Blob([csv], {type: "text/csv"})
        },
        round2Two(num, rou=2) {
            return +(Math.round(num + `e+${rou}`) + `e-${rou}`)
        },

        /** 契約プランの依頼可能サイト判定
         * @param url: ユーザーのinput url
         * @param permitSites: ユーザーの契約プランの依頼可能対象サイト郡
         * @returns {boolean}
         */
        getRequestSite(url, permitSites) {
            // siteList: [] or ["mercari"]
            return permitSites.filter(site => new RegExp(site).test(url))
        },
        /** v-forの:keyに渡すユニークなkeyを生成
         * @param item: スカラー値を渡す, NG: [], {}, (), OK: "abc", 123
         * @param idx:
         * @returns {string}
         */
        uniKey(item, idx) {
            return `${item}-${idx}`
        },
        /**
         * 文字列の一部をアスタリスクにして、隠す
         * @param text{String}: 隠したい文字列
         * @param start{Number}: 最初の位置
         * @param end{Number}: 最後の位置
         * @returns {string}
         */
        hideText(text = "", start = 4, end = -4) {
            const secret = text.slice(start, end);
            return text.replace(secret, "***");
        },
        /**
         * ローカルと本番でパス変更
         * @returns {string}
         */
        changeEnviron() {
            return document.domain === "localhost" ?
                "http:0.0.0.0:8080/" : "https://api-exponential.awiiin.com/"
        },
        /**
         * 現在のドル円相場取得
         * @returns {Promise<void>}
         */
        async getDollarRate() {
            // const url = "https://api-exponential.awiiin.com/front-validation/get_dollar"
            // const result = await this.requestWithToken(url, "GET")
            // return result["data"]["detail"]
            const ref = ["admin_config", "tool_config"]
            const data = await this.getDocument(ref)
            return data.usd_jpy_rate
        },
        /**スナックバー更新
         * @param color str 色
         * @param msg str メッセージ
         * @param timeout int 表示時間
         */
        changeStateSnackbar(color, msg, timeout = 3000) {
            const snackbar = {
                flag: true,
                color: color,
                msg: msg,
                timeout: timeout
            }
            return this.$store.dispatch("state/changeSnackbar", snackbar)
        },
        async setPrivateNews(uid, title, body, link) {
            const new_message = {
                body: body,
                title: title,
                link: link,
                created_at: this.yyyy_mm_dd_hh_mm_ss(),
                is_new_message: true,
                unread: true
            }
            const ref = ["news", uid]
            let data = await this.getDocument(ref)
            if (data) {
                data.private.unshift(new_message)
            } else {
                data = {
                    general: [],
                    private: [new_message]
                }
            }
            await this.setDocument(ref, data)
        },
        /**
         * ゼロ埋め
         * @param num ゼロ埋めしたい数値
         * @param len 桁数
         * @returns {string}
         */
        zeroPadding(num, len) {
            return (Array(len).join('0') + num).slice(-len);
        },
        /**expo機能のプラン判定
         * @param types limits(数値タイプ) or bools(2値タイプ)
         * @param area 場所 例: 依頼, 編集
         * @param point 具体的な制御する箇所 例: ooボタン, xx機能
         * @returns {string}
         */
        checkExpo(types, area, point) {
            const expo = this.$store.getters["state/storeExpo"][types]
            return ["sites", "single_sites"].includes(types) ? expo : expo[area][point]
        },
        /**rabbit機能のプラン判定
         * @param types 場所 例: 依頼, 編集
         * @param point 具体的な制御する箇所 例: ooボタン, xx機能
         * @returns {string}
         */
        checkRabbit(types, point) {
            const rabbit = this.$store.getters["state/storeRabbit"]
            return rabbit[types][point]
        },
        // this.checkRabbit("bools", "auto_msg_response")
        async updateCount(ref, field, num = 1) {
            const postUrl = `${this.api}/count_update`
            const params = {
                ref: ref,
                field: field,
                num: num
            }
            return await this.requestWithToken(postUrl, "POST", params)
        },
    },
}