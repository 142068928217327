<template>
  <v-app-bar app color="#283e66" flat dark clipped-left>
    <v-app-bar-nav-icon v-on:click.stop="controlDrawerChild()"/>
    <v-toolbar-title><span style="font-weight: bold; font-size: 1.4em">Exponential</span> <small style="font-size: 0.7em">ver {{ version }}</small></v-toolbar-title>
    <v-spacer></v-spacer>

    <Bell />
    <v-btn v-show="user.selectedSeller.length" @click="wrapCopy(user.uid)" text>ユーザーIDコピー</v-btn>
    <v-btn v-show="user.selectedSeller.length" @click="wrapCopy(user.selectedSeller)" text>選択中セラーコピー</v-btn>
    <v-btn to="/datastudio" text>expo累計実績</v-btn>
    <v-btn to="/plan" text>料金プラン</v-btn>
    <v-btn to="/" text v-if="v.isNone(auth.currentUser)">ログイン</v-btn>
<!--    <v-btn text v-else>ログイン中</v-btn>-->
    <div style="width: 10%" class="ml-2">
      <v-select :items="user.sellers" v-model="user.selectedSeller" @change="compMove" outlined hide-details dense label="選択中セラー"/>
    </div>

    <v-snackbar v-model="isLink" :timeout="-1" light top right outlined>
      ebayアカウントの連携がされていません。左上の三本線メニューをクリックし「アカウント情報」ページの「新規連携」ボタンから連携をしてください。
    </v-snackbar>
  </v-app-bar>
</template>

<script>
import Bell from "@/components/home/Bell";

export default {
  components: {Bell},
  computed: {
    version() {
      return this.$store.getters["state/version"]
    },
    user: {
      get() {
        return this.$store.getters["state/storeUser"]
      },
      set(value) {
        this.$store.dispatch("state/setUser", value);
      },
    },
    isLink() {
      // 一瞬 v-snackbar が表示されるためuidが存在するとき(ログイン済み)のみ判定
      if (this.user.uid.length === 0) return false
      return this.isLogin() && this.user.sellers.length === 0
    }
  },
  watch: {
    "user.uid": {
      handler: function (uid) {
        if (this.v.isNone(uid) || uid === "") return
        this.getCounter()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    controlDrawerChild() {
      this.drawer = !this.drawer
      this.$emit("controlDrawer", this.drawer);
    },
    async getCounter() {
      const ref = this.doc(this.db, "counter", this.user.uid)
      this.counterSnap = this.onSnapshot(ref, (doc) => {
        this.$store.dispatch("state/addCounter", doc.data())
      })
    },
    wrapCopy(value) {
      this.doCopy(value)
      this.changeStateSnackbar("success", "コピーしました")
    },
    async compMove() {
      const path = this.$route.path
      await this.$router.push("/setting-account")
      await this.$router.push(path)
    },
  },
  data: () => ({
    selectedSeller: "",
    getStatus: true,
    isAdmin: false,
    drawer: false,
  }),

}
</script>