// バリデーションで利用する関数をまとめていきます。
// return: bool統一でお願いします。
// 利用例: if (this.v.isNone(input)) {console.log("This is None Type")}
import siteInfo from "@/mixins/siteInfo";

export default {
    /** null or undefined判定
     * @param subject
     * @returns {boolean}
     */
    isNone: (subject) => {
        return subject === null || subject === undefined
    },
    /** 半角数値判定
     * @param subject
     * @returns {boolean}
     */
    isHunNum: (subject) => {
        return !!String(subject).match(/^[0-9]+$/)
    },
    /** 半角数値判定
     * @param subject
     * @returns {boolean}
     */
    isHunNumMinus: (subject) => {
        return !!String(subject).match(/^[0-9-]+$/)
    },
    /**
     * 半角数値のフロート型判定
     * @param subject
     * @returns {boolean}
     */
    isHunFloat: (subject) => {
        return !!String(subject).match(/^[0-9.]+$/)
    },
    /** 半角英数字判定
     * @param subject
     * @returns {boolean}
     */
    isHanEiSu(subject) {
        return !!String(subject).match(/^[A-Za-z0-9 ]*$/)
    },
    /** 契約プランの依頼可能サイト判定
     * @param url: ユーザーのinput url
     * @param permitSites: ユーザーの契約プランの依頼可能対象サイト郡
     * @returns {boolean}
     */
    isPermitSite(url = "", permitSites = []) {
        return permitSites.some(site => new RegExp(site).test(url))
    },
    isInvalidUrl(url) {
      const reg = /^https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+$/
      return !reg.test(url)
    },
    /**
     * 詳細URLか検索結果一覧URLの判定
     * @param url
     * @param site
     * @param type
     * @returns {boolean}
     */
    isSiteType(url, site, type) {
        return new RegExp(siteInfo[site][type]).test(url)
    },
    /**
     * メールアドレスパターン判定
     * @param email
     * @returns {boolean}
     */
    isInValidEmail(email) {
        const reg = new RegExp(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+\.[A-Za-z0-9]+$/);
        return !reg.test(email)
    },
    /**
     * パスワード文字数判定
     * @param password
     * @returns {boolean}
     */
    isInValidPassword(password) {
        return password.length < 6
    },
    /**
     * 文字列、リストの空判定
     * @param val list or str
     * @returns {boolean}
     */
    isEmpty(val) {
        return val.length === 0
    },
    /** プラン毎の各上限以内で利用可能かどうか
     * 例: 依頼回数上限 >= 現在依頼回数
     * @param maxLimit リミット上限
     * @param now 現状
     * @param lessThan default => lt input => ge or gt or lt
     * @returns {boolean}
     */
    isOverLimit(maxLimit, now, lessThan = "") {
        switch (lessThan) {
            case "ge":
                return maxLimit >= now
            case "gt":
                return maxLimit > now
            case "le":
                return maxLimit <= now
            default:
                return maxLimit < now
        }
    }
}

