
export default {
    mercari: {
        out: /search|user/,
        in: /item/,
        kana: "メルカリ"
    },
    "auctions.yahoo": {
        out: /search|seller|category|list/,
        in: /page/,
        kana: "ヤフオク"
    },
    netmall: {
        out: /search|brand|cmp|series|cate|shop/,
        in: /product/,
        kana: "ハードオフ"
    },
    suruga: {
        out: /search/,
        in: /product/,
        kana: "駿河屋"
    },
    rakuten: {
        out: /search|category|ranking|daily/,
        in: /item/,
        kana: "楽天"
    },
    fril: {
        out: /category|search|brand|keyword|ranking|purchase|channel|trend|query/,
        in: /item/,
        kana: "ラクマ"
    },
    "shopping.yahoo": {
        out: /search|category|ranking/,
        in: /store/,
        kana: "ヤフショ"
    },
    paypaymall: {
        out: /search|category/,
        in: /store/,
        kana: "ペイペイモール"
    },
    amazon: {
        out: /gp|s|movers-and-shakers|most-gifted|most-wished-for|stores/,
        in: /dp/,
        kana: "アマゾン"
    },
    daikokuya: {
        out: /new|search|brandpage|c\d+|category/,
        in: /ja-jp\/\d+/,
        kana: "大黒屋"
    },
    vector: {
        out: /list|c|t|bag/,
        in: /item/,
        kana: "ベクトルパーク"
    },
    brandoff: {
        out: /s_header_keyword|s_outstock|sellers/,
        in: /detail/,
        kana: "ブランドオフ"
    },
    komehyo: {
        out: /search/,
        in: /product/,
        kana: "コメ兵"
    },
    ginzo: {
        out: /search/,
        in: /collections|products/,
        kana: "銀蔵"
    },
    reclo: {
        out: /search|brand|new_arrivals|categories|topics|price_down|men|women/,
        in: /item/,
        kana: "レクロ"
    },
    digimart: {
        out: /cat|search/,
        in: /shop/,
        kana: "デジマート"
    },
    "g-rare": {
        out: /search/,
        in: /fashion|watch|jewelry/,
        kana: "ギャラリーレア"
    },
    trefac: {
        out: /\/store\/(t[a-z\d]+b\/|search_result.html)/,
        in: /\/store\/\d+/,
        kana: "トレファク"
    },
    "yodobashi.com": {
        out: /word=.*|\/category\/.*/,
        in: /product\/\d+\//,
        kana: "ヨドバシカメラ"
    },
    "shop.kitamura.jp": {
        out: /ec\/list.*/,
        in: /\/ec\/(pd|used)\/\d+/,
        kana: "キタムラカメラ"
    },
    "snkrdunk.com": {
        out: /\/(products|apparels)\?|\/apparel-categories\/\d+/,
        in: /(apparel-free-used-items\/\d+|apparels\/\d+|products\/[\da-zA-Z-_]+)(\/primary|\/used\/\d+)?/,
        kana: "スニーカーダンク"
    },
    "monotaro.com": {
        out: /\/s\//,
        in: /\/p\/\d+\/\d+\//,
        kana: "モノタロウ"
    },
    // mandarake: {
    //     out: /order\/listPage\/list/,
    //     in: /order\/detailPage\/item\?itemCode=\d+|itemCode=\d+/
    // },
    // axes: {
    //     out: /p\/search/,
    //     in: /c\/item/
    // },
    // hedy: {
    //     out: /p\/search/,
    //     in: /https:\/\/www.hedy.jp\/c\//
    // },
    // "o-kuraonline": {
    //     out: /(https:\/\/o-kuraonline.jp\/\?s=)|(https:\/\/o-kuraonline.jp\/page.*)/,
    //     in: /https:\/\/o-kuraonline.jp\/[a-zA-Z]\d+\//
    // },
    // retro: {
    //     out: /fs\/retro\/GoodsSearchList.html/,
    //     in: /fs\/retro\/.*/
    // },
    // ueda78: {
    //     out: /shop\/shopbrand.html\?/,
    //     in: /shop\/shopdetail.html\?brandcode=\d+/
    // },
    // "2ndstreet": {
    //     out: /search\?(category|keyword|brand|other)/,
    //     in: /goods\/detail/
    // }
}