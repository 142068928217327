const Chat = () => import("../components/customer/chat/Main")
const Tracking = () => import("../components/customer/tracking/Main")


export default [
    {
        path: "/chat",
        name: "chat",
        component: Chat,
        meta: {title: "expo|チャット"}
    },
    {
        path: "/tracking",
        name: "tracking",
        component: Tracking,
        meta: {title: "expo|発送追跡一覧"}
    }
]
