const Rules = () => import("../components/home/other/Rules")
const Privacy = () => import("../components/home/other/Privacy")
const Transaction = () => import("../components/home/other/Transaction")
const Plan = () => import("../components/home/plan/Main.vue")
const Datastudio = () => import("../components/home/Datastudio.vue")

const path = [
    {
        path: "/plan",
        name: "plan",
        component: Plan,
        meta: {title: "expo|料金プラン"}
    },
    {
        path: "/transaction",
        name: "transaction",
        component: Transaction,
        meta: {title: "expo|特定商取引法表記"}
    },
    {
        path: "/privacy",
        name: "privacy",
        component: Privacy,
        meta: {title: "expo|個人情報保護"}
    },
    {
        path: "/rules",
        name: "rules",
        component: Rules,
        meta: {title: "expo|利用規約"}
    },
    {
        path: "/datastudio",
        name: "datastudio",
        component: Datastudio,
        meta: {title: "expo|累計実績"}
    }
]


export default path