<template>
  <v-menu max-height="800px" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-badge :content="unread" :value="unread">
        <v-icon
            v-bind="attrs"
            v-on="on"
        >
          mdi-bell
        </v-icon>
      </v-badge>
    </template>
    <v-card class="pa-2" width="600">
      <v-row>
        <v-col cols="12">
          <h4 class="pb-1">全体お知らせ</h4>
          <v-card height="300" class="overflow-auto" outlined>
            <v-expansion-panels accordion>
              <v-expansion-panel
                  v-for="(item, index) in news.general"
                  :key="index"
              >
                <v-expansion-panel-header
                    @click="setRead(item)"
                    :color="item.unread? 'white':'grey lighten-4'">
                <span>
                  {{ item.title }}<br>
                  <span style="font-size: 1px">{{ item.created_at }}</span>
                </span>
                  <span v-if="item.unread" style="color:#CB4335">new</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container v-html="item.body">
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-col>
        <v-col cols="12">
          <h4 class="pb-1">個別お知らせ</h4>
          <v-card height="300" class="overflow-auto" outlined>
            <v-expansion-panels accordion>
              <v-expansion-panel
                  v-for="(item, index) in news.private"
                  :key="index"
              >
                <v-expansion-panel-header
                    @click="setRead(item)"
                    :color="item.unread? 'white':'grey lighten-4'">
                <span>{{ item.title }}
                  <br><span style="font-size: 1px">{{ item.created_at }}</span>
                </span>
                  <span v-if="item.unread" style="color:#CB4335">new</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container v-html="item.body">
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

  </v-menu>
</template>

<script>
const sound = new Audio(require('@/assets/notification.mp3'));
export default {
  created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'state/setUser') {
        this.getNotices()
      }
    })
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["state/storeUser"]
      },
      set(value) {
        this.$store.dispatch("state/setUser", value);
      },
    }
  },
  methods: {
    async getNotices() {
      const ref = this.doc(this.db, "news", this.user.uid)
      this.unsubscribe = this.onSnapshot(ref, (doc) => {
        this.unread = 0
        const data = doc.data()
        if (this.v.isNone(data)) return
        this.soundPlay(data.updated_at)
        for (let type of ["private", "general"]) {
          this.news[type] = data[type]
          this.unread += this.news[type].filter(val => val.unread).length
        }
      })
    },
    soundPlay(new_updated_at) {
      if (this.updated_at !== new_updated_at) {
        if (this.updated_at !== -1 && this.checkRabbit('bools', 'push_notice')) sound.play()
        this.updated_at = new_updated_at
      }
    },
    async setRead(item) {
      item.unread = false
      const ref = ["news", this.user.uid]
      await this.setDocument(ref, this.news)
    }
  },
  destroyed() {
    if (this.unsubscribe) this.unsubscribe()
  },
  data: () => ({
    news: {
      private: [],
      general: [],
    },
    updated_at: -1,
    unsubscribe: "",
    unread: 0
  }),
}
</script>