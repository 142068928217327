const SignUpAndIn = () => import("../components/home/auth/SignUpAndIn")
const DoneView = () => import("../components/home/auth/DoneView")
const SuccessConnect = () => import("../components/home/auth/SuccessConnect")
const FailureConnect = () => import("../components/home/auth/FailureConnect")
const SuccessAccount = () => import("../components/home/auth/SuccessMakeAccount")

const path = [
    {
        path: "/auth",
        name: "auth",
        component: SignUpAndIn,
        mata: {title: "サインアップ・サインイン"},
    },
    {
        path: "/success-account",
        name: "success-account",
        component: SuccessAccount,
        mata: {title: "会員登録完了"},
    },
    {
        path: `/done/:path`,
        name: "done-view",
        component: DoneView,
        meta: {title: "完了前画面"}
    },
        {
        path: `/success-connect`,
        name: "success-connect",
        component: SuccessConnect,
        meta: {title: "ebay連携完了"}
    },
        {
        path: `/failure-connect`,
        name: "failure-connect",
        component: FailureConnect,
        meta: {title: "ebay連携失敗"}
    },
]

export default path
