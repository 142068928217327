const List = () => import("../components/listing/list/List")
const ParallelEdit = () => import("../components/listing/edit/ParallelEdit")
const SingleEdit = () => import("../components/listing/edit/SingleEdit")
const Csv = () => import("../components/listing/list/Csv")
const Direct = () => import("../components/listing/list/Direct")
const Result = () => import("../components/listing/list/Result")
const LabelManage = () => import("../components/listing/LabelManage")


const RequestParallel = () => import("../components/listing/request/Parallel")
const AutoRequest = () => import("../components/listing/request/auto/AutoRequest")


export default [
    {
        path: "/list",
        name: "list",
        component: List,
        meta: {title: "expo|商品一覧"}
    },
    {
        path: "/parallel-edit/:doc_id",
        name: "parallel-edit",
        component: ParallelEdit
    },
    {
        path: "/single-edit",
        name: "single-edit",
        component: SingleEdit
    },
    {
        path: "/direct-list/:doc_id",
        name: "direct-list",
        component: Direct
    },
    {
        path: "/csv-list/:doc_id",
        name: "csv-list",
        component: Csv
    },
    {
        path: "/result-list/:request_type/:listing_id",
        name: "result-list",
        component: Result
    },
    {
        path: "/request-parallel",
        name: "request-parallel",
        component: RequestParallel
    },
    {
        path: "/auto-request",
        name: "auto-request",
        component: AutoRequest
    },
    {
        path: "/label-manage",
        name: "label-manage",
        component: LabelManage
    },
]
