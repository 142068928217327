export default {
    namespaced: true,

    state: {
        item: {
            id: "",
            title: "",
            seller: "",
            picture: "",
            unread: "",
            isSold: "",
            buyers: "",
            updated_at: "",
            price: "",
            sku: "",
            min_price: "",
            accept_price: "",
            offer_enable: "",
            flags:"",
            visible:""
        },
        buyer: {
            archived:"",
            flag:"",
            memo:"",
            message_id:"",
            name:"",
            reply_deadline:"",
            sender_type:"",
            unread:""
        },
        seller:"",
        sortOption: "",
        flagOption: "",
        searchTarget: "",
    },
    getters: {
        item: (state) => state.item,
        buyer: (state) => state.buyer,
        seller: (state) => state.seller,
        sortOption: (state) => state.sortOption,
        flagOption: (state) => state.flagOption,
        searchTarget: (state) => state.searchTarget,
    },
    actions: {
        setItem({commit}, val) {
            commit("setItem", val);
        },
        setBuyer({commit}, val) {
            commit("setBuyer", val);
        },
        setSeller({commit}, val) {
            commit("setSeller", val);
        },
        setSortOption({commit}, val) {
            commit("setSortOption", val);
        },
        setFlagOption({commit}, val) {
            commit("setFlagOption", val);
        },
        setSearchTarget({commit}, val) {
            commit("setSearchTarget", val);
        },
    },

    mutations: {
        setItem(state, val) {
            state.item = Object.assign({},val);
        },
        setBuyer(state, val) {
            state.buyer = val;
        },
        setSeller(state, val) {
            state.seller = val;
        },
        setSortOption(state, val) {
            state.sortOption = val;
        },
        setFlagOption(state, val) {
            state.flagOption = val;
        },
        setSearchTarget(state, val) {
            state.searchTarget = val;
        },
    },
}

