import Router from "vue-router";
import VueRouter from "vue-router";
import Vue from "vue";
import auth from "./auth";
import home from "./home";
import other from "./other";
import customer from "@/router/customer";
import listing from "@/router/listing";
import setting from "@/router/setting";
import mixin from "../mixins/mixin";

// import search from "./search"
// import user from "./user"

Vue.use(Router);

const routes = [
    ...home,
    ...customer,
    ...listing,
    ...auth,
    ...other,
    ...setting
    // ...search,
    // ...user
]
// export default new Router({
//     mode: "history",
//     routes
// })

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

// 非ログイン時でも、アクセス許可するページをリストに入れるとアクセスできるようになります
const freeAccessList = [
    "/success-account", "/success-connect", "/failure-connect", "/plan", "/rules", "/privacy", "/transaction",
    "/datastudio", "/test_lp"
];

router.beforeEach((to, from, next) => {
    const isNotLogin = mixin.data().auth.currentUser === null
    // 非ログイン & トップページに推移する場合
    if (isNotLogin && to.path !== "/") {
        // freeAccessで指定したページは、非ログイン時でもアクセス許可
        if (freeAccessList.includes(to.path)) {
            next();
        } else {
            // 非ログイン時は、アクセス先をホームに矯正。（画面リロード時も同じ）
            next(false, {path: "/"});
            location.reload();
        }
    } else {
        next(); // 通常のページ推移は何もしない
    }
});

export default router;
