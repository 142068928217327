import Vue from "vue";
import Vuex from "vuex";
import state from "./state"
import chat from "@/store/chat";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        state,
        chat
    }
})
