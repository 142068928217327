<template>
  <v-app class="mb-0">
    <v-overlay :value="overlay.flag" :absolute="true" opacity="0.6" z-index="999">
      <p style="font-size: 3em">{{ overlay.msg }}</p>
    </v-overlay>
    <MenuPage @controlDrawer="controlDrawer"/>
    <SideMenu :drawer="drawer"/>
    <v-main>
      <div class="pa-3">
        <v-fade-transition mode="out-in">
          <router-view/>
        </v-fade-transition>

      </div>
      <!--      <Footer/>-->
    </v-main>
    <!--    <v-footer color="green" dark app>-->
    <!--       <Bottom/>-->
    <!--    </v-footer>-->
    <v-snackbar v-model="snackbar.flag" :timeout="snackbar.timeout" light top right outlined :color="snackbar.color">
      {{ snackbar.msg }}
    </v-snackbar>
  </v-app>
</template>

<script>
import MenuPage from "@/components/home/HeaderMenu";
// import Bottom from "./components/home/BottomMenu";
// import Footer from "./components/home/FooterMenu"
import SideMenu from "./components/home/SideMenu"

export default {
  name: 'App',
  methods: {
    controlDrawer(flag) {
      this.drawer = flag
    }
  },
  computed: {
    snackbar() {
      return this.$store.getters["state/storeSnackbar"]
    },
    overlay() {
      return this.$store.getters["state/storeOverlay"]
    }
  },
  components: {
    MenuPage,
    // Bottom,
    // Footer,
    SideMenu
  },
  created() {
  },
  watch: {
    snackbar() {
      // console.log(this.snackbar)
      return this.snackbar
    },
    deep: true
  },
  data: () => ({
    drawer: false
  })
}
</script>

