<template>
  <v-navigation-drawer app clipped v-model="drawerFlag">
    <v-list nav dense>

      <v-list-item link to="/setting-account">
        <v-list-item-icon>
          <v-icon large color="orange">mdi-account-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-title style="font-weight: bold">アカウント情報</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>

      <!-- 顧客対応 -->
      <v-list-item v-for="obj in customers" :key="obj.title" :to="obj.link" link>
        <v-list-item-icon>
          <v-icon :color="obj.color">{{ obj.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title v-text="obj.title"></v-list-item-title>
      </v-list-item>

      <!--      <v-list-group no-action prepend-icon="mdi-account-multiple-outline">-->

      <!--        <template v-slot:activator>-->
      <!--          <v-list-item-content>-->
      <!--            <v-list-item-title>顧客対応</v-list-item-title>-->
      <!--          </v-list-item-content>-->
      <!--        </template>-->

      <!--        <v-list-item v-for="obj in customers" :key="obj.title" :to="obj.link" link>-->
      <!--          <v-list-item-title v-text="obj.title"></v-list-item-title>-->
      <!--        </v-list-item>-->

      <!--      </v-list-group>-->

      <!-- 出品 -->
      <v-list-item v-for="obj in requests" :key="obj.title" :to="obj.link" link>
        <v-list-item-icon>
          <v-icon :color="obj.color">{{ obj.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title v-text="obj.title"></v-list-item-title>
      </v-list-item>
      <!--      <v-list-group no-action prepend-icon="mdi-cart-variant">-->

      <!--        <template v-slot:activator>-->
      <!--          <v-list-item-title>出品</v-list-item-title>-->
      <!--        </template>-->

      <!--        <v-list-item v-for="obj in requests" :key="obj.title" :to="obj.link" link>-->
      <!--          <v-list-item-title v-text="obj.title"></v-list-item-title>-->
      <!--        </v-list-item>-->

      <!--      </v-list-group>-->

      <!-- チャット設定 -->
      <v-list-group no-action prepend-icon="mdi-forum-outline">

        <template v-slot:activator>
          <v-list-item-title>設定 - チャット</v-list-item-title>
        </template>

        <v-list-item v-for="obj in customerSetting" :key="obj.title" :to="obj.link" link>
          <v-list-item-title v-text="obj.title"></v-list-item-title>
        </v-list-item>

      </v-list-group>

      <!-- 出品設定 -->
      <v-list-group no-action prepend-icon="mdi-cog-outline">

        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>設定 - 出品</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="obj in requestSetting" :key="obj.title" :to="obj.link" link>
          <v-list-item-title v-text="obj.title"></v-list-item-title>
        </v-list-item>

      </v-list-group>

      <v-list-item link to="/setting-drop">
        <v-list-item-icon>
          <v-icon>mdi-robot-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>設定 - 在庫管理</v-list-item-title>
      </v-list-item>

      <!-- お知らせ -->
      <v-list-item link href="https://ebay-marketing-tool.com/export-expo-release/" target="_blank">
        <!--      <v-list-item link href="https://sites.google.com/wish-in-iori.com/exponential-info/news" target="_blank">-->
        <v-list-item-icon>
          <v-icon color="blue">mdi-pentagram</v-icon>
        </v-list-item-icon>
        <v-list-item-title>お知らせ</v-list-item-title>
      </v-list-item>

      <v-list-item link href="https://ebay-marketing-tool.com/qa/" target="_blank">
<!--      <v-list-item link href="https://sites.google.com/wish-in-iori.com/exponential-info/frequently-asked-questions"-->
<!--                   target="_blank">-->
        <v-list-item-icon>
          <v-icon color="cyan">mdi-help</v-icon>
        </v-list-item-icon>
        <v-list-item-title>よくある質問</v-list-item-title>
      </v-list-item>

      <!-- マニュアル -->
      <v-list-item v-if="manualLink" link :href="manualLink" target="_blank">
        <v-list-item-icon>
          <v-icon color="purple">mdi-file-document-multiple-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>マニュアル</v-list-item-title>
      </v-list-item>

      <!-- 修正フォーム -->
      <!--      <v-list-item link href="https://share.hsforms.com/1Ok3jaw_pQK2iFMdlltSp6w4xxfk" target="_blank">-->
      <v-list-item link href="https://forms.gle/PYVcEqCpqFnobkfp8" target="_blank">
        <v-list-item-icon>
          <v-icon>mdi-email-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>機能追加要望フォーム</v-list-item-title>
      </v-list-item>

      <!-- ログアウト -->
      <v-list-item link @click="signOutWrap()">
        <v-list-item-icon>
          <v-icon>mdi-logout-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-title>ログアウト</v-list-item-title>
      </v-list-item>

      <v-spacer/>

      <v-list-group no-action prepend-icon="mdi-lock-outline">

        <template v-slot:activator>
          <v-list-item-title>プライバシーと利用規約</v-list-item-title>
        </template>

        <v-list-item :to="{ name: 'rules' }">
          <v-list-item-title>利用規約</v-list-item-title>
        </v-list-item>

        <v-list-item :to="{ name: 'privacy' }">
          <v-list-item-title>個人情報保護方針</v-list-item-title>
        </v-list-item>

        <v-list-item :to="{ name: 'transaction' }">
          <v-list-item-title>特定商取引法に基づく表記</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>

  </v-navigation-drawer>
</template>

<script>
export default {
  props: ["drawer"],
  methods: {
    signOutWrap() {
      // let user = this.$store.getters["state/storeUser"]
      // user.uid = ""
      // this.$store.dispatch("state/setUser", user)
      this.auth.signOut()
      this.$router.push(`/`)
    }
  },
  watch: {
    drawer(flag) {
      this.drawerFlag = flag
    }
  },
  data: () => ({
    manualLink: "https://ebay-marketing-tool.com/expo-manual/",
    drawerFlag: false,
    val: false,
    customers: [
      {title: "チャット", link: "/chat", icon: "mdi-chat-plus-outline", color: "red"},
      {title: "発送追跡", link: "/tracking", icon: "mdi-airplane", color: ""}
    ],
    requests: [
      {title: "商品一覧", link: "/list", icon: "mdi-gift-outline", color: "blue"},
      {title: "大量依頼", link: "/request-parallel", icon: "mdi-cloud-download", color: "green"},
      {title: "個別依頼", link: "/single-edit", icon: "mdi-download-circle-outline", color: "cyan"},
      {title: "自動定期出品", link: "/auto-request", icon: "mdi-robot-happy-outline", color: "red"},
      {title: "管理番号確認", link: "/label-manage", icon: "mdi-clipboard-list-outline"}
    ],
    customerSetting: [
      {title: "テンプレート", link: "/setting-chat-template"},
      {title: "チャットフラグ", link: "/setting-flag"},
      {title: "自動応答", link: "/setting-auto-response"},
      {title: "バイヤー名簿", link: "/setting-buyerlist"},
      {title: "販売データ", link: "/setting-sold-list"}

    ],
    requestSetting: [
      {title: "出品総合", link: "/setting-list"},
      {title: "編集", link: "/setting-edit"},
    ],
  })
}
</script>